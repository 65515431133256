@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&family=Yeseva+One&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  transition: all 0.3s ease;
}

a, h1, h2, h3, h4, h5, h6, p {
  overflow-wrap: break-word;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
}

/* ROOT COLORS */
:root {
  --primary-green: #005046;
  --secondary-green: #056F62;
  --primary-red: #622f2fe3;
  --primary-gold: #ffc107;
  --secondary-red: #F9C4D7;
  --gray: #ddd;
  --white: #FFF;
  --black: #000;
  --ash: #F0F4F1;
}

.caption-underline {
  height: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-self: center;
  align-items: center;
}
.caption-underline .green {
  width: 35%;
  height: 100%;
  background-color: var(--primary-green);
}
.caption-underline .white {
  width: 30%;
  height: 100%;
  background-color: var(--white);
}
.caption-underline .red {
  width: 35%;
  height: 100%;
  background-color: var(--primary-red);
}

.error {
  animation: shake 0.3s;
  /* color: var(--primary-red);  */
  border: solid 2px var(--primary-red) !important;
}
@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

/* MOBILE STYLING (SET TO DISPLAY NONE) */
.hamburgerMenu {
  display: none;
}
.side-bar-mobile {
  display: none;
}
.mobile-header {
  display: none;
}

/* HEADER */
header {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
}
header .first-header {
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 30px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;

}
header .first-header .f-header-left {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
header .first-header .f-header-left img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
header .first-header .f-header-left h4 {
  color: var(--primary-green);
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
header .first-header .f-header-right {
  display: flex;
  gap: 30px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
header .first-header .f-header-right .header-icons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}
header .first-header .f-header-right .header-icons h5 {
  color: var(--primary-green);
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
header .first-header .f-header-right .header-icons p {
  color: var(--primary-red);
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
header .marquee-container {
  background-color: var(--white);
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  overflow: hidden;
}

header .marquee-container span {
  width: fit-content;
  padding: 5px 20px;
  color: var(--white);
  font-family: "Work Sans";
  font-size: 14px;
  background-color: var(--primary-red);
  z-index: 3;
}

header .marquee-container a {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  text-decoration: none;
  white-space: nowrap;
  box-sizing: border-box;
}

header .marquee-container .marquee {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  animation: marquee 15s linear infinite;
}

header .marquee-container .marquee-content {
  display: inline-block;
  padding-right: 50px; 
}

header .marquee-container .marquee-content::after {
  content: attr(data-text); 
  padding-left: 50px;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* NAVLINKS */
header .second-header {
  width: 100%;
  height: fit-content;
  padding: 20px 70px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--primary-green);
}
header .second-header nav {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
header nav .navlinks {
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 50px;
  list-style-type: none;
}
header nav .navlinks .navLink, 
header nav .dropdown-toggle {
  color: var(--white);
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: none;
  border: none;
  cursor: pointer;
}
header nav .navlinks .active {
  color: var(--primary-red);
  font-weight: 600;
}
/* NAV DROPDOWN */
header nav .navbar-dropdown {
  position: relative;
  display: inline-block;
  z-index: 900;
}
header nav .dropdown-menu {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: var(--white);
  min-width: 280px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 10px;
  border-radius: 5px;
}
header nav .dropdown-menu li {
  list-style-type: none;
}
header nav .dropdown-item {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: var(--black);
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
header nav .dropdown-item:hover {
  background-color: var(--gray);
  color: var(--primary-red);
  font-weight: 500;
}
header nav .navbar-dropdown:hover .dropdown-menu {
  display: block;
}
/* SEARCHBOX */
header .nav-search {
  display: flex;
  gap: 80px;
  flex-wrap: nowrap;
  justify-content: flex-end;
  width: 400px;
}
header .search-container {
  position: relative;
  box-sizing: border-box;
  width: fit-content;
}
header .search-mainbox {
  box-sizing: border-box;
  position: relative;
  width: 230px;
  height: 50px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  border-radius: 160px;
  background-color: var(--white);
  transition: all 0.3s ease;
}
header .search-checkbox:focus {
  border: none;
  outline: none;
}
header .search-checkbox:checked {
  right: 10px;
}
header .search-checkbox:checked ~ .search-mainbox {
  width: 50px;
}
header .search-checkbox:checked ~ .search-mainbox .search_input {
  width: 0;
  height: 0px;
}
header .search-checkbox:checked ~ .search-mainbox .search-iconContainer {
  padding-right: 8px;
}
header .search-checkbox {
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 17px;
  top: 12px;
  z-index: 9;
  cursor: pointer;
  appearance: none;
}
header .search_input {
  box-sizing: border-box;
  height: 100%;
  width: 170px;
  background-color: transparent;
  border: none;
  outline: none;
  padding-bottom: 4px;
  padding-left: 10px;
  font-size: 18px;
  font-weight: 500;
  color: var(--primary-green);
  transition: all 0.3s ease;
  font-family: 'Work Sans';
}
header .search_input::placeholder {
  color: var(--secondary-green);
  font-family: 'Work Sans';
  font-weight: 400;
  font-size: 16px;
}
header .iconContainer {
  box-sizing: border-box;
  padding-top: 5px;
  width: fit-content;
  transition: all 0.3s ease;
}
header .search_icon {
  box-sizing: border-box;
  fill: var(--primary-green);
  font-size: 1.3em;
}
/* PROFILE MENU */
header .profile-button {
  background-color: transparent;
  background: none;
  border: none;
  cursor: pointer;
}
header .profile-menu {
  position: absolute;
  top: 220px; 
  right: 30px; 
  background-color: var(--white); 
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
  z-index: 10;
}
header .profile-menu input,
header .profile-menu button {
  display: block;
  margin-bottom: 10px;
  width: 200px; 
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
header .profile-menu input:placeholder-shown {
  font-size: 14px;
  font-family: 'Work Sans';
  color: var(--secondary-green);
}
header .profile-menu .signin-button {
  background-color: var(--primary-green);
  color: var(--white);
  cursor: pointer;
}
header .profile-menu .create-account-button {
  background-color: transparent;
  color: var(--primary-red);
  text-decoration: underline;
  cursor: pointer;
}
header .profile-menu .signin-button:hover {
  background-color: var(--secondary-green);
}
header .password-input {
  position: relative;
}
header .password-input input {
  padding-right: 40px;
}
header .toggle-password-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
/* FOOTER  */
footer {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--primary-green);
  color: var(--white);
}
footer .footer-top {
  width: 100%;
  height: fit-content;
  gap: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
footer .footer-top h6 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 50px;
}
footer .footer-top h5 {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 20px;
}
footer p, 
footer li {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
footer .footer-top .f-top-one {
  height: fit-content;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
footer .footer-top .f-top-one img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
footer .footer-top .f-top-one h5 {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
footer .footer-top .f-top-one p {
  align-self: flex-start;
}
footer .footer-top .f-top-two {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
footer .footer-top .f-top-two ul li {
  list-style-type: none;
  margin-bottom: 10px;
}
footer .footer-top .f-top-three p {
  margin-bottom: 10px;
}
footer .footer-top .f-top-four {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
footer .footer-top .f-top-four .subscribe-container {
  width: fit-content;
  position: relative;
}
footer .footer-top .f-top-four .subscribe-container input {
  width: 300px;
  padding: 20px 50px 20px 20px;
  border-radius: 5px;
  border: none;
  outline: none;
  position: relative;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
footer .footer-top .f-top-four .subscribe-container input:placeholder-shown {
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--secondary-green);
}
footer .footer-top .f-top-four .subscribe-container button {
  border: none;
  outline: none;
  background-color: transparent;
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
}
footer hr {
  width: 100%;
  background-color: var(--white);
}
footer .footer-bottom {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
footer .footer-bottom .footer-links {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
}
/* PAGE CAPTION */
.page-caption {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  background-image: url('https://github.com/btom7447/Abkon-Portfolio/blob/master/src/assets/image/breadcrumb.png?raw=true');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
}
.departments-caption {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-image: url('https://github.com/btom7447/Abkon-Portfolio/blob/master/src/assets/image/breadcrumb.png?raw=true');
}
.schools-caption {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-image: url('https://github.com/btom7447/Abkon-Portfolio/blob/master/src/assets/image/breadcrumb.png?raw=true');
}
.doctors-caption {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-image: url('https://github.com/btom7447/Abkon-Portfolio/blob/master/src/assets/image/breadcrumb.png?raw=true');
}
.services-caption {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-image: url('https://github.com/btom7447/Abkon-Portfolio/blob/master/src/assets/image/breadcrumb.png?raw=true');
}
.diseases-caption {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-image: url('https://github.com/btom7447/Abkon-Portfolio/blob/master/src/assets/image/breadcrumb.png?raw=true');
}
.health-services-caption {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-image: url('https://github.com/btom7447/Abkon-Portfolio/blob/master/src/assets/image/breadcrumb.png?raw=true');
}

.contact-caption {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  position: relative;
  background-image: url('https://github.com/btom7447/Abkon-Portfolio/blob/master/src/assets/image/breadcrumb.png?raw=true');
}

.page-caption .page-caption-text, 
.departments-caption .page-caption-text, 
.schools-caption .page-caption-text, 
.doctors-caption .page-caption-text, 
.services-caption .page-caption-text,
.diseases-caption .page-caption-text,
.health-services-caption .page-caption-text,
.contact-caption .page-caption-text {
  position: absolute;
  top: 50%;
  left: 10%;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--primary-green);
}
.page-caption .page-caption-text h5, 
.departments-caption .page-caption-text h5, 
.schools-caption .page-caption-text h5, 
.doctors-caption .page-caption-text h5, 
.services-caption .page-caption-text h5, 
.diseases-caption .page-caption-text h5,
.health-services-caption .page-caption-text h5,
.contact-caption .page-caption-text h5 {
  font-family: "Yeseva One";
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--primary-red);
}
.page-caption .caption-underline, 
.departments-caption .caption-underline, 
.schools-caption .caption-underline, 
.doctors-caption .caption-underline, 
.services-caption .caption-underline, 
.diseases-caption .caption-underline,
.health-services-caption .caption-underline,
.contact-caption .caption-underline {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
}
/* CONTACT CONTAINER */
.get-in-touch {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--white);
}
.get-in-touch-caption {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.get-in-touch-caption h5 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-transform: uppercase;
  text-align: center;
  color: var(--primary-green);
}
.get-in-touch-caption h6 {
  font-family: "Yeseva One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: var(--primary-red);
}
.contact-container {
  margin-top: 50px;
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}
.contact-container .contact-box {
  border-radius: 5px;
  padding: 50px 20px;
  background-color: var(--primary-green);
  color: var(--white);
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.contact-container .contact-box:hover {
  color: var(--white);
  background-color: var(--primary-red);

}
.contact-container .contact-box svg path {
  stroke: var(--white);
}
.contact-container .contact-box h5 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
}
.contact-container .contact-box p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
/* TESTIMONIALS */
.doctor-profile .testimonial-container {
  background-color: var(--white);
  padding-bottom: 50px;
  padding-top: 50px;
}
.testimonial-container {
  width: 100%;
  height: fit-content;
  padding: 100px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: var(--ash);
  padding-bottom: 100px;
}
.testimonial-container .testimonial-caption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.testimonial-container .testimonial-caption h5 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-transform: uppercase;
  text-align: center;
  color: var(--primary-green);
}
.testimonial-container .testimonial-caption h3 {
  font-family: "Yeseva One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: var(--primary-red);
}
.testimonial-container .testimonial-carousel {
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
}
.testimonial-container .testimonial-splide {
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.testimonial-container .testimonial-splide p {
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 140%;
  color: var(--black);
}
.testimonial-container .testimonial-splide h5 {
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 140%; 
  color: var(--black);
}
.testimonial-container .testimonial-splide .fa-star {
  font-size: 25px;
  color: var(--primary-gold);
  margin-right: 5px;
}
.testimonial-container .testimonial-splide .ratings .fa-star-half-alt {
  font-size: 25px;
  color: var(--primary-gold);
  margin-right: 5px;
}
.testimonial-container .testimonial-splide .ratings .fa-star-half-stroke {
  font-size: 25px;
  color: var(--primary-gold);
}
.testimonial-container .splide__arrow {
  font-size: 18px;
  background-color: transparent;  
}
.testimonial-container .splide__pagination {
  text-align: center;
  position: absolute;
  bottom: -30px;
}
.testimonial-container .splide__pagination__page {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 10px; 
  height: 10px; 
  border-radius: 50%;
  background-color: var(--primary-red);
  margin: 0 5px; 
  cursor: pointer;
}
.testimonial-container .splide__pagination__page.is-active {
  background-color: var(--primary-green); 
  align-self: center;
  justify-self: center;
}


/* LANDING PAGE */
.landing-page {
  width: 100%;
  height: fit-content;
  display: block;
}
.landing-page .hero-carousel-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
  position: relative;
  margin-bottom: 100px;
}
.landing-page .hero-carousel {
  width: 100%;
  height: fit-content;
}
.landing-page .hero-carousel button {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 20px 80px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  color: var(--white);
  background-color: var(--primary-green);
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(50%);
}
.landing-page .hero-carousel .hero-carousel-one {
  width: 100%;
  height: 90vh;
  background-image: url('../Assets/Images/HeroCarousel.png');
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
}
.landing-page .hero-carousel .hero-carousel-two {
  width: 100%;
  height: 90vh;
  background-image: url('../Assets/Images/HeroCarousel-one.png');
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
}
.landing-page .hero-carousel-caption {
  position: absolute;
  top: 20%;
  left: 10%;
  transform: translateY(20%);
}
.landing-page .hero-carousel-caption h5 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-transform: uppercase;
  color: var(--primary-green);
}
.landing-page .hero-carousel-caption h1 {
  font-family: "Yeseva One";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--primary-red);
}
.landing-page .carousel-nested-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
  position: absolute;
  left: 0;
  bottom: -200px;
}
.landing-page .carousel-nested-buttons button {
  width: 250px;
  height: 100px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 30px;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: var(--white);
  background-color: var(--primary-green);
  box-shadow: 0px 8px 40px 0px rgba(0,0,0,0.2);
}
.landing-page .carousel-nested-buttons button a {
  text-decoration: underline;
}

/* ANNOUNCEMENT SECTION */
.landing-page .announcement-container {
  margin-top: -100px;
  width: 100%;
  height: fit-content;
  padding: 50px 20px;
  padding-top: 200px;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--ash);
}
.landing-page .announcement-container .announcement-caption {
  width: fit-content;
  height: fit-content;
}
.landing-page .announcement-container .announcement-caption h3 {
  font-family: "Yeseva One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.landing-page .announcement-container .announcement-carousel {
  width: 100%;
  height: fit-content;
  margin-bottom: 20px;
}
.landing-page .announcement-box {
  width: 60%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.landing-page .announcement-box img {
  width: 100%;
  height: 700px;
  object-fit: cover;
}
.landing-page .announcement-box h5 {
  font-family: "Work Sans";
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
.landing-page .announcement-box p {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-align: center;
}
.landing-page .announcement-carousel .splide__arrow {
  font-size: 18px;
  background-color: transparent;  
}
.landing-page .announcement-carousel .splide__pagination {
  text-align: center;
  position: absolute;
  bottom: -30px;
}
.landing-page .announcement-carousel .splide__pagination__page {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 10px; 
  height: 10px; 
  border-radius: 50%;
  background-color: var(--primary-red);
  margin: 0 5px; 
  cursor: pointer;
}
.landing-page .announcement-carousel .splide__pagination__page.is-active {
  background-color: var(--primary-green); 
  align-self: center;
  justify-self: center;
}
/* WELCOME SECTION */
.landing-page .welcome-section {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.landing-page .welcome-section .welcome-section-caption {
  width: 60%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.landing-page .welcome-section .welcome-section-caption h5 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-transform: uppercase;
  color: var(--primary-green);
}
.landing-page .welcome-section .welcome-section-caption h3 {
  font-family: "Yeseva One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--primary-red);
}
.landing-page .welcome-section .welcome-section-caption p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-align: center;
  margin-top: 30px;
}
.landing-page .welcome-section .welcome-section-poster {
  width: 80%;
  height: 300px;
  position: relative;
  overflow: hidden; 
}
.landing-page .welcome-section .welcome-section-poster img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  display: block; 
}
.landing-page .welcome-section .welcome-section-poster .caption-underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
/* ABOUT US SECTION */
.landing-page .about-us-section {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  background-color: var(--ash);
}
.landing-page .about-us-section .about-us-caption {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.landing-page .about-us-section .about-us-caption h5 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-transform: uppercase;
  color: var(--primary-green);
}
.landing-page .about-us-section .about-us-caption h3 {
  font-family: "Yeseva One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--primary-red);
}
.landing-page .about-us-section .about-us-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.landing-page .about-us-section .toggle-display-container {
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.landing-page .toggle-display-container .button-list {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;
  border: solid 1px var(--primary-green);
}
.landing-page .toggle-display-container .button-list button {
  width: 100%;
  height: fit-content;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  color: var(--black);
}
.landing-page .toggle-display-container .button-list button svg path {
  fill: var(--primary-red);
}
.landing-page .toggle-display-container .button-list button.active {
  background-color: var(--primary-green);
  color: var(--white);
}
.landing-page .toggle-display-container .button-list button.active svg path {
  fill: var(--white);
}
.landing-page .toggle-display-container .button-list .learn-more-link {
  width: 100%;
  height: fit-content;
}
.landing-page .toggle-display-container .learn-more-link button {
  background-color: var(--primary-green);
  color: var(--white);
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
  border-top: solid 1px var(--white);
}
.landing-page .toggle-display-container .display-section {
  width: 100%;
  height: fit-content;
  padding: 20px;
}
.landing-page .display-section .about-us-display {
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.landing-page .about-us-display .about-left {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}
.landing-page .about-us-display .about-left h5 {
  font-family: "Work Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--black);
}
.landing-page .about-us-display .about-left p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--black);
}
.landing-page .about-us-display .about-left ul {
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start; 
  list-style-type: none;
  padding-left: 0; 
}
.landing-page .about-us-display .about-left ul li {
  position: relative; 
  width: 45%;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--black);
  padding-left: 20px; 
}
.landing-page .about-us-display .about-left ul li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: var(--primary-red);
  border-radius: 50%;
}
.landing-page .about-us-display .about-right {
  width: 600px;
  height: 500px;
}
.landing-page .about-us-display .about-right img {
  width: 100%;
  height: 50%;
  object-fit: cover;
  object-position: center;
  margin-bottom: 10px;
}
/* DEPARTMENTS AND FOCUS AREA */
.landing-page .departments-f-areas-section {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.landing-page .departments-f-areas-section .d-f-caption {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.landing-page .departments-f-areas-section .d-f-caption h5 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-transform: uppercase;
  text-align: center;
  color: var(--primary-green);
}
.landing-page .departments-f-areas-section .d-f-caption h3 {
  font-family: "Yeseva One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: var(--primary-red);
}
.landing-page .departments-f-areas-section .departments-container {
  width: 80%;
  height: fit-content;
  display: grid;
  gap: 0px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  align-items: stretch;
}
.landing-page .departments-container .departments {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--black);
  border: solid 1px var(--gray);
  background-color: var(--white); 
  border-radius: 5px;
  cursor: pointer;
  height: 100%;
}
.landing-page .departments-container .departments:hover {
  background-color: var(--primary-green);
  color: var(--white);
  border: none;
}
.landing-page .departments-container .departments:hover svg path {
  fill: var(--white);
}
/* BOOK APPOINTMENT */
.landing-page .book-appointment {
  position: relative;
  width: 100%;
  height: fit-content;
  overflow-y: auto; 
  background: url('../Assets/Images/booking-appointment-image.png');
  background-size: cover;
  background-position: top left;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 50px;
  display: flex;
  gap: 30px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  z-index: 5;
}
.landing-page .book-appointment::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}
.landing-page .book-appointment .book-appointment-caption {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  z-index: 10;
}
.landing-page .book-appointment .book-appointment-caption h3 {
  font-family: "Yeseva One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--primary-green);
}
.landing-page .book-appointment .book-appointment-caption p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--black);
}
.landing-page .book-appointment .book-appointment-caption span {
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--primary-green);
}
.landing-page .book-appointment .book-appointment-caption .red-bold {
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: var(--primary-red);
}
.landing-page .book-appointment .booking-form {
  width: 70%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  z-index: 10;
}
.landing-page .book-appointment .booking-form .form-group {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.579px;
  color: var(--black);
}
.landing-page .book-appointment .booking-form .patient-type-group {
  width: 65%;
}
.landing-page .book-appointment .booking-form .phone-number-group,
.landing-page .book-appointment .booking-form .name-group, 
.landing-page .book-appointment .booking-form .email-group {
  width: 30%;
}
.landing-page .book-appointment .booking-form .appointment-date-group, 
.landing-page .book-appointment .booking-form .time-group {
  width: 20%;
}
.landing-page .book-appointment .booking-form .message-group {
  width: 50%;
}
.landing-page .book-appointment .booking-form .upload-file-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.landing-page .book-appointment .booking-form .upload-file-group label {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.landing-page .book-appointment .booking-form .upload-file-group p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: italic;
  font-weight: 200;
  line-height: 23.579px;
  color: var(--black);
}
.landing-page .book-appointment .booking-form .upload-file-group input {
  width: 30%;
}
.landing-page .book-appointment .booking-form input {
  width: 100% !important;
  height: 60px;
  padding: 20px !important;
  border-radius: 4px; 
  border: solid 1px var(--primary-green);
  outline: solid 1px var(--primary-green);
  color: var(--black);
}
.landing-page .book-appointment .booking-form textarea {
  width: 100% !important;
  height: fit-content;
  padding: 20px !important;
  border-radius: 5px; 
  border: solid 1px var(--primary-green);
  outline: solid 1px var(--primary-green);
  color: var(--black);
}
.landing-page .book-appointment .booking-form input, 
.landing-page .book-appointment .booking-form textarea:placeholder-shown {
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  background-color: var(--white);
}
.patient-type-input {
  -webkit-appearance: none; 
  -moz-appearance: none; 
  appearance: none;
  padding: 10px 80px 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px; 
  width: 100%;
  box-sizing: border-box;
  font-size: 16px; 
  position: relative;
}
.patient-type-input::-ms-expand {
  display: none;
}

.landing-page .booking-form .file-input-wrapper {
  width: 30%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: fit-content;
  padding: 20px;
  border-radius: 5px;
  border: solid 1px var(--primary-green);
  outline: solid 1px var(--primary-green);
  background-color: var(--white);
  cursor: pointer;
}
.landing-page .booking-form .file-input-wrapper .icon-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
}
.landing-page .booking-form .file-input {
  display: none;
}
.landing-page .book-appointment .booking-form .booking-submit {
  justify-self: center;
  align-self: center;
  margin: auto;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  padding: 20px 60px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: var(--white);
  background-color: var(--primary-green);
}
.select-container {
  position: relative;
  width: 100%;
}
.booking-form .doctor-select .react-select__control {
  height: 60px;
  
}


/* OUR PROFESSIONALS */
.professionals-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 50px;
}
.professionals-container .our-professionals-caption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.professionals-container .our-professionals-caption h5 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-align: center;
  text-transform: uppercase;
  color: var(--primary-green);
}
.professionals-container .our-professionals-caption h3 {
  font-family: "Yeseva One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: var(--primary-red);
}
.doctors-carousel {
  width: 80%; 
}
.professionals-box {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.professionals-box .professionals-image {
  width: 100%;
  height: fit-content;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.professionals-box .professionals-image img {
  width: 100%;
  height: 400px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  object-fit: cover;
  object-position: top center;
}
.professionals-box .professionals-caption {
  width: 100%;
  min-height: 150px; 
  height: fit-content;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: var(--primary-green);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.professionals-box .professionals-caption h5 {
  font-family: "Work Sans";
  font-size: 14px; 
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-transform: uppercase;
  text-align: center;
  color: var(--white);
}
.professionals-box .professionals-caption h6 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--white);
  text-align: center;
}
.professionals-caption .professionals-links {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 20px;
}
.professionals-container .splide__pagination {
  text-align: center;
  position: absolute;
  bottom: -30px;
}
.professionals-container .splide__pagination__page {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 10px; 
  height: 10px; 
  border-radius: 50%;
  background-color: var(--primary-red);
  margin: 0 5px; 
  cursor: pointer;
}
.professionals-container .splide__pagination__page.is-active {
  background-color: var(--primary-green); 
  align-self: center;
  justify-self: center;
}

/* PLACES WE CAN BE FOUND */
.landing-page .locations-section {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: var(--white);
}
.landing-page .locations-section .locations-caption {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.landing-page .locations-section .locations-caption h5 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-transform: uppercase;
  text-align: center;
  color: var(--primary-green);
}
.landing-page .locations-section .locations-caption h3 {
  font-family: "Yeseva One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: var(--primary-red);
}
.landing-page .locations-section .locations-container {
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.landing-page .locations-section .locations-container .locations-box {
  width: 400px;
  height: 200px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;
  display: flex; 
  justify-content: center;
  align-items: flex-end; 
  border-radius: 20px;
  position: relative;
}
.landing-page .locations-container .locations-box img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
  border-radius: 20px;
  object-fit: cover;
}
.landing-page .locations-section .locations-container .locations-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  border-radius: 20px;
  z-index: 6;
}
.landing-page .locations-section .locations-container .locations-box h5 {
  color: var(--white);
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22.959px;
  text-align: center;
  z-index: 6;
}
/* ABOUT US PAGE */
.about-oauthc {
  width: 100%;
  height: fit-content;
}
.oauthc-history {
  width: 100%;
  padding: 50px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.oauthc-history h6 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-transform: uppercase;
  color: var(--primary-green);
  margin-bottom: 20px;
}
.oauthc-history p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--black);
  margin-bottom: 10px;
}
.about-oauthc .about-poster-text {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}
.about-oauthc .about-poster-text .about-oauthc-poster {
  width: 500px;
  height: 500px;
}
.about-oauthc .about-poster-text .about-oauthc-poster img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top center;
}
.about-oauthc .about-poster-text .about-oauthc-text {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}
.about-oauthc .about-poster-text .about-oauthc-text h5 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-transform: uppercase;
  color: var(--primary-green);
}
.about-oauthc .about-poster-text .about-oauthc-text h4 {
  font-family: "Yeseva One";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--primary-red);
}
.about-oauthc .about-poster-text .about-oauthc-text p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--black);
}
.about-oauthc .about-poster-text .about-oauthc-text p span {
  text-decoration: underline;
}
.about-oauthc .vision-mission-goal {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  gap: 50px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: var(--ash);
}
.about-oauthc  .vision-mission-goal .vision-mission-goal-box {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.about-oauthc  .vision-mission-goal .vision-mission-goal-box.goals {
  width: 100%;
}
.about-oauthc  .vision-mission-goal .vision-mission-goal-box p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--black);
}
.about-oauthc  .vision-mission-goal .vision-mission-goal-box h5 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-transform: uppercase;
  color: var(--primary-green);
  background-color: var(--gray);
  padding: 10px;
  border-radius: 10px;
}
/* PEOPLE CULTURE  */
.about-oauthc .people-culture {
  width: 100%;
  height: fit-content;
  padding: 0px 0px 50px 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 40px;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--ash);
  position: relative;
}
.about-oauthc .people-culture .people-culture-text {
  margin-top: 50px;
  width: calc(100% - 300px);
  height: fit-content;
  display: flex;
  flex-direction: column;
}
.about-oauthc .people-culture .people-culture-text h5 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-transform: uppercase;
  color: var(--primary-green);
}
.about-oauthc .people-culture .people-culture-text h4 {
  font-family: "Yeseva One";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--primary-red);
  margin-bottom: 10px;
}
.about-oauthc .people-culture .people-culture-text p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--black);
  margin-bottom: 20px;
}
.about-oauthc .people-culture .people-culture-poster {
  width: 220px;
  height: calc(100% + 100px);
  background-image: url('../Assets/Images/people-culture-poster-desktop.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  position: absolute;
  top: -100px;
  right: 0;
}

.our-values-container {
  /* margin-top: 50px; */
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: var(--ash);
}
.our-values-container .our-values-caption {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.our-values-container .our-values-caption h5 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.88px;
  text-transform: uppercase;
  text-align: center;
  color: var(--primary-green);
}
.our-values-container .our-values-caption h6 {
  font-family: "Yeseva One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  color: var(--primary-red);
}
.our-values-container .our-values-section {
  width: 100%;
  height: fit-content;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); 
}
.our-values-section .our-values {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.our-values-section .our-values img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
.our-values-section .our-values h5 {
  text-align: center;
  font-family: "Work Sans";
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--black);
}
.our-values-section .our-values p {
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  color: var(--black);
}

/* DEPARTMENTS AND CENTERS */
.departments-centers-section {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  gap: 50px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--white);
}
.departments-centers-section .departments-centers-text {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
}
.departments-centers-section .departments-centers-text p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 159.4%;
  color: var(--black);
}
.departments-centers-section .departments-log {
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.departments-centers-section .schools-gap {
  width: 100%;
  height: fit-content;
  padding: 20px;
}
.departments-centers-section .departments-log .departments-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); 
  gap: 100px 50px;
  width: 100%;
  height: fit-content;
  transition: opacity 0.5s ease-in-out;
}
.departments-centers-section .departments-log .department {
  height: 300px;
  padding: 30px;
  border-radius: 10px;
  position: relative;
}
.departments-centers-section .departments-log .department img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-position: top center;
  object-fit: cover;
  border-radius: 10px;
}
.departments-centers-section .departments-log .department img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 19, 19, 0.7);
}
.departments-centers-section .departments-log .department .department-caption {
  position: absolute;
  bottom: 0px;
  transform: translateY(50%); 
  margin: auto;
  padding: 20px;
  width: 80%;
  height: fit-content;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  background-color: var(--white);
  color: var(--primary-red);
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 159.4%; 
  text-transform: capitalize;
}
.departments-centers-section .departments-log .departments-pagination {
  margin-top: 50px;
  padding: 20px;
  justify-self: center;
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.departments-centers-section .departments-log .departments-pagination button {
  background-color: var(--ash);
  border: none;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: var(--primary-green);
  transition: background-color 0.3s, color 0.3s;
}
.departments-centers-section .departments-pagination button:hover {
  background-color: var(--secondary-green);
  color: var(--white);
}
.departments-centers-section .departments-pagination button.active {
  background-color: var(--primary-green); 
  color: #fff;
}
.appointment-poster {
  width: 100%;
  height: fit-content;
  margin-top: 200px;
  padding: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: right;
  align-items: flex-end;
  background-color: var(--ash);
  position: relative;
}
.appointment-poster .appointment-poster-pic {
  width: 400px;
  height: calc(100% + 300px);
  background-image: url('../Assets/Images/Group\ 1.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  position: absolute;
  top: -299px;
  left: 0px;
  z-index: 2; 
}
.appointment-poster .appointment-poster-caption {
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.appointment-poster .appointment-poster-caption h5 {
  color: var(--primary-red);
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.appointment-poster .appointment-poster-caption h6 {
  color: var(--primary-green);
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.appointment-poster .appointment-poster-caption button {
  padding: 20px 30px;
  border-radius: 10px;
  border: none;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--white);
  background-color: var(--primary-green);
  cursor: pointer;
}

/* DEPARTMENT DETAILS */
.department-details {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  gap: 30px  ;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.department-details .department-overview-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 100px;
}
.department-details .department-overview-container .departments-poster {
  width: 500px;
  height: fit-content;
  border-radius: 20px;
}
.department-details .department-overview-container .departments-poster img {
  width: 100%;
  height: 300px;
  border-radius: 20px;
  object-fit: cover;
  object-position: top center;
}
.department-details .department-overview-container .departments-overview {
  width: calc(100% - 500px);
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}
.department-details .department-overview-container .departments-overview h5 {
  font-family: "Yeseva One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 20px;
  color: var(--black);
}
.department-details .department-overview-container .departments-overview h6 {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  text-transform: capitalize;
  color: var(--black);
}
.department-details .department-overview-container .departments-overview p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; 
  color: var(--black);
} 
.department-details .departments-vision {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.department-details .departments-vision h5 {
  font-family: "Yeseva One";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: var(--black);
  margin-top: 30px;
}
.department-details .departments-vision p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; 
  text-transform: capitalize;
  color: var(--black);
  margin-top: 10px;
}
.department-details .departments-facilities {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  background-color: var(--ash);
  padding: 30px 20px;
  border-radius: 5px;
}
.department-details .departments-facilities h5 {
  font-family: "Yeseva One";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: var(--black);
}
.department-details .departments-facilities h6 {
  font-family: "Yeseva One";
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  color: var(--black);
}
.department-details .departments-facilities p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; 
  text-transform: capitalize;
  color: var(--black);
}
.department-details .departments-facilities ul {
  width: 100%;
  height: fit-content;
  list-style-type: none; 
  padding-left: 0; 
}
.department-details .departments-facilities li {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-transform: capitalize;
  color: var(--black);
  display: flex; 
  align-items: center;
  margin-bottom: 10px;
}
.department-details .departments-facilities .list-icon {
  margin-right: 20px; 
  font-size: 16px;
  color: var(--black); 
}
.department-details .departments-contacts {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-top: 20px;
}
.department-details .departments-contacts h5 {
  font-family: "Yeseva One";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: var(--black);
}
.department-details .departments-contacts p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; 
  text-transform: capitalize;
  color: var(--black);
}

/* DOCTORS LOG */
.find-doctor {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}
.find-doctor .doctors-log {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.find-doctor .search-filters {
  margin-bottom: 20px;
  height: fit-content;
  padding: 20px;
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.find-doctor .search-filters input {
  padding: 13px 10px;
  border-radius: 4px;
  border: solid 1px var(--black);
  outline: solid 1px var(--black);
  font-family: "Work Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 400; 
  line-height: normal;
  text-transform: capitalize;
}

/* REACT SELECT  */
.doctor-select {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400; 
  line-height: normal;
  text-transform: capitalize;
}
.doctor-select .react-select__control {
  border: 2px solid var(--primary-green) !important;
  border-radius: 4px;
  padding: 5px;
  box-shadow: none;
  background-color: var(--white);
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  outline: none !important;
  cursor: pointer !important;
}
.doctor-select .react-select__control:hover {
  outline: none !important;
  box-shadow: none !important;
  border: solid 2px var(--primary-green) !important;
}
.doctor-select .react-select__option {
  padding: 5px 10px;
  background-color: white !important;
  color: var(--black) !important;
  font-family: "Work Sans";
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  outline: none;
  cursor: pointer;
}
.doctor-select .react-select__option:hover {
 background-color: var(--gray) !important;
}
.doctor-select .react-select__multi-value {
  background-color: var(--ash) !important;
  border-radius: 2px;
  margin: 3px;
  padding: 2px;
  cursor: pointer;
}
.doctor-select .react-select__multi-value__remove {
  color: var(--grascale);
  cursor: pointer;
}
.doctor-select .react-select__placeholder {
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: var(--grascale);
}
.doctor-select .react-select__dropdown-indicator {
  padding: 0 8px;
  color: var(--grascale);
}
.css-1u9des2-indicatorSeparator {
  display: none;
}


.find-doctor .doctors-log .doctors-container {
  width: 80%;
  height: fit-content;
  display: grid;
  gap: 50px !important;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
.find-doctor .doctors-log .doctor-card {
  width: fit-content;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
.find-doctor .doctor-card .doctor-card-caption {
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
}
.find-doctor .doctor-card .doctor-card-caption img {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
  object-fit: cover;
  object-position: top center;
  margin-right: 5px;
}
.find-doctor .doctor-card .doctor-card-text {
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}
.find-doctor .doctor-card .doctor-icon {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.find-doctor .doctor-card .doctor-card-caption h4 {
  font-family: "Yeseva One";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: var(--primary-green);
}
.find-doctor .doctor-card .doctor-card-caption h6 {
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: #848383;
}
.find-doctor .doctor-card p {
  font-family: "Work Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: var(--black);
}
.find-doctor .doctors-log .doctor-card hr {
  width: 100%;
  background-color: var(--black);
} 
.find-doctor .doctor-card-buttons {
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.find-doctor .doctor-card-buttons .doctor-profile-button {
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: var(--black);
  border: solid 1px black;
  background-color: transparent;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
}
.find-doctor .doctor-card-buttons .doctor-profile-button:hover {
  background-color: var(--gray);
}
.find-doctor .doctor-card-buttons .doctor-appointment-button {
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: var(--white);
  background-color: var(--primary-green);
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}
.find-doctor .doctor-card-buttons .doctor-appointment-button:hover {
  background-color: var(--secondary-green);
}
.find-doctor .doctors-pagination {
  margin-top: 50px;
  padding: 20px;
  justify-self: center;
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.doctors-pagination button {
  background-color: var(--ash);
  border: none;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: var(--primary-green);
  transition: background-color 0.3s, color 0.3s;
}
.doctors-pagination button:hover {
  background-color: var(--secondary-green);
  color: var(--white);
}
.doctors-pagination button.active {
  background-color: var(--primary-green); 
  color: #fff;
}

/* DOCTOR PROFILE */
.doctor-details {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.back-button {
  width: 100%;
  height: fit-content;
  padding: 50px 50px 20px 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-self: flex-start;
  align-items: center;
  gap: 5px;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--black);
}
.doctor-details .doctor-overview-container {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
}
.doctor-details .doctor-overview-container .doctor-poster {
  width: 35%;
  height: 500px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.doctor-details .doctor-overview-container .doctor-poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.doctor-details .doctor-poster .socials-link {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.doctor-details .doctor-poster .socials-link a {
  cursor: pointer;
}
.doctor-details .doctor-overview {
  width: 60%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}
.doctor-details .doctor-overview h3 {
  font-family: "Yeseva One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: var(--primary-green);
}
.doctor-details .doctor-overview h5 {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.doctor-details .doctor-overview .doctor-icons-cont {
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}
.doctor-details .doctor-overview .doctor-icons {
  width: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.doctor-details .doctor-overview .doctor-icons p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: var(--black);
  opacity: 0.8;
}
.doctor-details .doctor-overview h4 {
  text-align: left;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px; 
  margin-bottom: 20px;
  margin-top: 20px;
}
.doctor-details .doctor-overview p {
  text-align: left;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; 
  color: var(--black);
}

/* OAUTHC LOCATION */
.oauthc-location {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}
.oauthc-location .oauthc-location-caption {
  width: 100%;
  height: fit-content;
}
.oauthc-location .oauthc-location-caption p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; 
  text-transform: capitalize;
  color: var(--black);
}
.oauthc-location .oauthc-location-container {
  width: 100%;
  height: fit-content;
  margin-top: 20px;
}
.oauthc-location .oauthc-location-log {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}
.oauthc-location .hospital-units {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 20px;
}
.oauthc-location .hospital-units .hospital-units-poster {
  width: 700px;
  height: 300px;
  border-radius: 10px;
}
.oauthc-location .hospital-units .hospital-units-poster img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  object-position: top center;
}
.oauthc-location .hospital-units .hospital-units-caption {
  height: fit-content;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.oauthc-location .hospital-units .hospital-units-caption h4 {
  font-family: "Yeseva One";
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 53.486px; 
  letter-spacing: 1.5px;
  color: var(--black);
}
.oauthc-location .hospital-units .hospital-units-caption > P {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 34.036px;
  color: var(--black);
  margin-bottom: 20px;
}
.oauthc-location .hospital-units .hospital-units-caption .unit-icons {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 10px;
}
.oauthc-location .hospital-units .hospital-units-caption .unit-icons p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-left: 20px;
  margin-right: 10px;
  color: var(--secondary-green);
}
.oauthc-location .hospital-units .hospital-units-caption .unit-links {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-left: 20px;
  margin-right: 10px;
  color: var(--secondary-green);
}
.oauthc-location .hospital-units .hospital-units-caption .list-icon {
  font-family: "Work Sans";
  font-size: 16px;
  color: var(--secondary-green);
}

/* HEALTH SERVICES */
.health-services-section .health-service-text {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-wrap: wrap; 
}
.health-services-section .health-service-text span {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}
.health-services-section .health-service-text p {
  width: 100%;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 15px;
}
.health-services-section .services-log {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  gap: 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.health-services-section .services-log .services-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); 
  gap: 100px 50px;
  width: 100%;
  height: fit-content;
  transition: opacity 0.5s ease-in-out;
}
.health-services-section .services-log .service {
  height: 300px;
  padding: 30px;
  border-radius: 10px;
  position: relative;
}
.health-services-section .services-log .service img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 10px;
}
.health-services-section .services-log .service img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 19, 19, 0.7);
}
.health-services-section .services-log .service .service-caption {
  position: absolute;
  bottom: 0px;
  transform: translateY(50%); 
  margin: auto;
  padding: 20px;
  width: 80%;
  height: fit-content;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  background-color: var(--white);
  color: var(--primary-red);
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 159.4%; 
  text-transform: capitalize;
}
.health-services-section .services-log .services-pagination {
  margin-top: 50px;
  padding: 20px;
  justify-self: center;
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.health-services-section .services-log .services-pagination button {
  background-color: var(--ash);
  border: none;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: var(--primary-green);
  transition: background-color 0.3s, color 0.3s;
}
.health-services-section .services-pagination button:hover {
  background-color: var(--secondary-green);
  color: var(--white);
}
.health-services-section .services-pagination button.active {
  background-color: var(--primary-green); 
  color: #fff;
}

/* DISEASES AND SYMPTOMS */
.diseases-symptoms-section {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}
.diseases-symptoms-section .diseases-symptoms-nav {
  width: 100%;
  height: fit-content;
  padding: 20px;
  display: flex;
  flex: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  border-radius: 5px;
  background-color: var(--primary-green);
}
.diseases-symptoms-nav .diseases-nav-left h4 {
  font-family: "Yeseva One";
  font-size: 34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--white);
}
.diseases-symptoms-nav .diseases-nav-left p {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--white);
  margin-bottom: 50px;
}
.diseases-symptoms-nav .diseases-nav-left .diseases-nav-search-container {
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.diseases-symptoms-nav .diseases-nav-search-container h5 {
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: var(--white);
}
.diseases-symptoms-nav .diseases-nav-search-container .disease-search {
  min-width: 300px;
  padding: 15px;
  border-radius: 30px;
  background-color: var(--white);
  color: var(--black);
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
  outline: none;
}
.diseases-symptoms-nav .diseases-nav-right {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.diseases-symptoms-nav .diseases-nav-right h5 {
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: var(--white);
  margin-bottom: 20px;
}
.diseases-symptoms-nav .diseases-nav-right .alphabet-buttons {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}
.diseases-symptoms-nav .diseases-nav-right .alphabet-buttons button {
  width: 50px;
  height: 50px;
  padding: 5px;
  border: none;
  border-radius: 100%;
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: var(--white);
  background-color: var(--primary-red);
}
.diseases-symptoms-nav .diseases-nav-right .alphabet-buttons button.active {
  color: var(--primary-red);
  background-color: var(--white);
}
/* DISEASES AND SYMPTOMS DISPLAY */
.diseases-symptoms-section .diseases-display-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 50px;
}
.diseases-symptoms-section .diseases-display-container h6 {
  align-self: flex-end;
  justify-self: flex-end;
  width: 500px; 
  text-align: justify;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
}
.diseases-symptoms-section .diseases-symptoms-display {
  width: 100%;
  height: fit-content;
}
.diseases-symptoms-section .diseases-symptoms-display h3 {
  width: 100%;
  text-align: justify;
  font-family: "Work Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--black);
  margin-bottom: 20px;
}
.diseases-symptoms-section .diseases-symptoms-display ul {
  width: 100%;
  height: fit-content;
  padding: 10px 50px;
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  list-style-type: none;
}
.diseases-symptoms-section .diseases-symptoms-display li {
  width: 300px;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
}
.diseases-symptoms-section .diseases-symptoms-display .disease-links {
  text-decoration: underline;
  color: var(--primary-red);
}
.diseases-symptoms-section .diseases-symptoms-display .disease-links:hover {
  font-weight: 400;
  text-decoration: none;
  color: var(--primary-green);
}

/* TEST AND PROCEDURES */
.tests-procedures-details {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.tests-procedures-details h4 {
  font-family: "Yeseva One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: var(--black);
  margin-bottom: 20px;
}
.tests-procedures-details h5 {
  font-family: "Yeseva One";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: var(--black);
  margin-top: 20px;
}
.tests-procedures-details h6 {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  text-transform: capitalize;
  color: var(--black);
  margin-bottom: 20px; 

}
.tests-procedures-details p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; 
  text-transform: capitalize;
  color: var(--black);
}


/* CONTACT US */
.contact-us {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;  
}
.contact-us .our-values-container {
  margin-top: 0px;
}
.contact-us-map {
  width: 100%;
  padding: 50px;
}
.contact-us-map .map {
  width: 100%;
  height: 400px; 
}
.contact-us-form {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}
.contact-get-in-touch {
  width: 45%;
  height: fit-content;
}
.contact-get-in-touch .get-in-touch {
  padding: 0;
}
.contact-get-in-touch .contact-container {
  margin-top: 0px;
  width: 100%;
  height: fit-content;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 0px;
}
.contact-get-in-touch .get-in-touch-caption {
  display: none;
}
.contact-form {
  width: 40%;
  height: fit-content;
}
.contact-form form {
  margin-top: 60px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-self: flex-start;
  align-items: flex-start;
  border-radius: 5px;
  border: solid 1px var(--primary-green);
}
.contact-form form input, 
.contact-form form textarea {
  padding: 20px;
  font-family: "Work Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: var(--primary-green);
  border: solid 1px var(--primary-green);
}
.contact-form form input::placeholder, 
.contact-form form textarea::placeholder {
  color: var(--primary-green);
}
.contact-form form .contact-name-input {
  width: 50%;
  height: fit-content;
  border-top-left-radius: 5px;
}
.contact-form form .contact-email-input {
  width: 50%;
  height: fit-content;
  border-top-right-radius: 5px;
}
.contact-form form .contact-subject-input {
  width: 100%;
  height: fit-content;
}
.contact-form form .contact-message-input {
  width: 100%;
  min-height: 180px;
  height: fit-content;
  padding: 10px;
}
.contact-form form .contact-submit-button {
  width: 100%;
  height: fit-content;
  padding: 20px;
  border: none;
  text-align: center;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  color: var(--white);
  background-color: var(--primary-green);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

/* TERMS OF CONDITION
PRIVACY POLICY */
.privacy-policy-page {
  width: 100%;
  height: fit-content;
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.privacy-policy-page h1 {
  font-family: "Yeseva One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}
.privacy-policy-page h5 {
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}
.privacy-policy-page p {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px; 
}
.privacy-policy-page ul li {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
  margin-left: 20px;
  list-style-type:symbols(-);
}
.privacy-policy-page ol li {
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 10px;
}

/* STUDENT PORTAL */
.student-portal-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--white);
  z-index: 2100;
}
.student-portal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;

}
.student-portal .student-portal-poster {
  width: 50%;
  height: 100vh;
  background-image: url(../Assets/Images/student-portal.png);
  background-position: center;
  background-size: cover;
}
.student-portal .student-portal-text {
  width: 50%;
  height: fit-content;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center; 
  gap: 20px;
}
.student-portal .portal-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.student-portal .student-portal-text img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.student-portal .student-portal-text h4 {
  color: var(--primary-green);
  font-family: "Work Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.student-portal .portal-content {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
}
.student-portal .portal-content .coming-soon {
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  color: var(--secondary-green);
  background-color: var(--gray);
}
.student-portal .portal-content h1 {
  margin-top: 20px;
  font-family: "Work Sans";
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--primary-red);
}
.student-portal .portal-content p {
  margin-top: 20px;
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; 
  color: var(--black); 
}
.student-portal .student-portal-form {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: center;
  align-items: center; 
  margin-top: 20px;
}
.student-portal .student-portal-form input {
  width: 300px;
  padding: 20px;
  border-radius: 10px;
  border: solid 1px var(--ash);
  outline: solid 1px var(--black);
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.student-portal .student-portal-form button {
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  background-color: var(--primary-green);
  color: var(--white);
  font-family: "Work Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  cursor: pointer;
}

/* RESEARCH AND ETHICS */
.research-ethics {
  width: 100%;
  height: fit-content;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}
.research-ethics h1 {
  font-family: "Work Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--primary-red);
}
.research-ethics .research-carousel {
  width: 100%;
  height: fit-content;
  position: relative;
  margin-bottom: 50px;
}
.research-carousel .research-button {
  padding: 20px;
  width: 400px; 
  height: fit-content;
  background-color: var(--primary-green);
  position: absolute;
  top: 30px;
  right: 0;
}
.research-carousel .research-button h4 {
  font-family: "Work Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--white);
}
.research-carousel img {
  width: 100%;
  height: 500px;
  object-fit: cover;
  object-position: top center;
}
.research-carousel .research-carousel-two {
  width: 100%;
  height: 500px;
  background-image: url('../Assets/Images/HeroCarousel.png');
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
}
.research-carousel .research-carousel-three {
  width: 100%;
  height: 500px;
  background-image: url('../Assets/Images/HeroCarousel.png');
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
}
.research-carousel .splide__pagination {
  text-align: center;
  position: absolute;
  bottom: -30px;
}
.research-carousel .splide__pagination__page {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: 10px; 
  height: 10px; 
  border-radius: 50%;
  background-color: var(--primary-red);
  margin: 0 5px; 
  cursor: pointer;
}
.research-carousel .splide__pagination__page.is-active {
  background-color: var(--primary-green); 
  align-self: center;
  justify-self: center;
}
.research-ethics .research-application-rules {
  width: 100%;
  height: fit-content;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.research-ethics .research-application-rules, 
.research-ethics .research-application-rules div {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.research-ethics .research-application-rules h3 {
  width: 100%;
  text-align: center;
  font-family: "Work Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--black);
  margin-bottom: 30px;
}
.research-ethics .research-application-rules h6 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--black);
  margin-bottom: 20px;
}
.research-ethics .research-application-rules ol {
  margin-left: 30px;
  text-align: justify;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.research-ethics .research-application-rules li {
  margin-bottom: 10px;
}
.research-ethics .research-application-rules h6 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.research-ethics .research-form {
  position: relative;
  width: 100%;
  height: fit-content;
  overflow-y: auto; 
  background: url('../Assets/Images/booking-appointment-image.png');
  background-size: cover;
  background-position: top left;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 50px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 5;
}
.research-ethics .research-form::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}
.research-ethics form {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  z-index: 10;
} 
.research-ethics form .name-email-inputs {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
} 
.research-ethics form .name-email-inputs input {
  min-width: 400px;
  padding: 20px;
  background: transparent;
  border: solid 2px var(--primary-green);
  outline: solid 2px var(--primary-green);
  border: none;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--black);
} 
.research-ethics form .name-email-inputs :nth-child(1) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.research-ethics form .name-email-inputs :nth-child(2) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.research-ethics form h6 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--black);
  margin-bottom: 20px;
}
.research-ethics .research-checklists{
  width: 100%;
}
.research-ethics form .input-options {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.research-ethics form ol {
  margin-left: 30px;
  text-align: justify;
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.research-ethics form ol li {
  margin-bottom: 20px;
}
.research-ethics form ol span {
  margin-right: 10px; 
}
.research-ethics form ol input {
  margin-left: 10px; 
  margin-right: 5px;
  width: 20px;
}
.research-ethics form .input-options label {
  margin-right: 20px; 
}
.research-ethics .research-submit-button { 
  align-self: center;
  margin-top: 30px;
}
.research-ethics .research-submit-button p { 
  font-size: 18px;
  font-style: italic;
  font-weight: 600;
}
.research-ethics form button {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  color: var(--white);
  background-color: var(--primary-green);
  padding: 20px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  min-width: 200px;
}
.research-ethics form h5 {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: -20px;
  color: var(--black);
}
.research-ethics form .notification {
  font-family: "Work Sans";
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  color: var(--black);
}

.team-members-details {
  padding-top: 200px;
}